.card {
    background-color: var(--mantine-color-body);
  transition: 0.3s ease-in;

  }
  
  .avatar {
    border: rem(2px) solid var(--mantine-color-body);
  }

.card:hover{
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
  transition: 0.3s ease-in-out;
}