.awatar{
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
    transition: 0.2s ease-in;
}

.awatar:hover{
    transform: scale(1.05);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    cursor: pointer;
    transition: 0.5s ease-in-out;
}

.awatarBox{
    display: flex;
    flex-direction: column;
    justify-content:'center';
    align-items: center;
}

.awatarSlide{
    animation: fade-up 0.5s ease-in-out;
}

@keyframes fade-up{
	
	0%{
	opacity:0;
	transform: translateY(30px) scale(0.5);
	}
	
	100%{
	opacity:1;
	transform: translateY(0px) scale(1);
	}
}

