.card {
    position: relative;
    background-color: var(--mantine-color-body);
  }
  
  .rating {
    position: absolute;
    top: var(--mantine-spacing-xs);
    right: rem(12px);
    pointer-events: none;
  }
  
  .title {
    display: block;
    margin-top: var(--mantine-spacing-md);
    margin-bottom: rem(5px);
  }
  
  .action {
    background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-5));
  
    @mixin hover {
      background-color: light-dark(var(--mantine-color-gray-1), var(--mantine-color-dark-6));
    }
  }
  
  .footer {
    margin-top: var(--mantine-spacing-md);
  }