.icon {
    color: light-dark(var(--mantine-color-gray-5), var(--mantine-color-dark-3));
  }
  
  .name {
    font-family:
      Greycliff CF,
      var(--mantine-font-family);
  }

  .friendCard{
 transition: 0.2s ease-in;
  }

  .friendCard:hover{
    -webkit-transform: scale(1.03);
    transform: scale(1.03);
    transition: 0.2s ease-in-out;
  }